import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

function ScrollToTop() {
    const handleScrollToTop = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' })
    }

  return (
    <div className='toTop' onClick={handleScrollToTop}>
        <FontAwesomeIcon icon={faAngleUp} />
    </div>
  )
}

export default ScrollToTop