import React, { useContext } from 'react'
import { MovieNowPlayingContext } from './MovieContext'
import {API_OBJ, GET_option} from '../APIConfig'
import { objToString } from '../MovieFunctions.js';

function LoadMoreNowPlayingBtn( {page, setPage} ) {
  const {movieNowPlaying, setMovieNowPlaying} = useContext(MovieNowPlayingContext); 
  

  const handleAdd = () => {
      console.log('append items...');
      console.log('current page: '+page);
      page++
      console.log('topage: ' + page);


      const params = {
          'page': page,
          'language': 'zh-HK',
          'region': 'hk'
      };
  
      let urlParmas = objToString(params);
  
      fetch(API_OBJ.API_DOMAIN + '/3/movie/now_playing' + urlParmas, GET_option)
      .then(response => response.json())
      .then(response => {
        let newList = [];
        response.results.map((movie)=> {
          newList.push(movie);
        })

        setMovieNowPlaying([...movieNowPlaying, ...newList])
        setPage(page)
      })
      .catch(err => console.error(err));
  }



    
  return (
    <>
      <div className='loadmore_btn_container'>
        <div onClick={handleAdd} className='loadmore_btn'>查看更多</div>
      </div>
    </>
    
  )
}

export default LoadMoreNowPlayingBtn