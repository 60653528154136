import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate   } from 'react-router-dom'
import { objToString, stringToDate } from '../MovieFunctions.js';
import {API_OBJ, GET_option} from '../APIConfig'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faStar } from '@fortawesome/free-solid-svg-icons';
import { MovieContext } from './MovieContext.js';

import ImgDefault from '../logo.svg';
import MovieReviews from './MovieReviews.js';
import MovieCast from './MovieCast.js';

function SingleMovie() {

    let urlParams = useParams(); // use to get movie ID
    let navigate = useNavigate(); // use for back button

    // Get imgJSON value from App Component
    const {imgJSON} = useContext(MovieContext)


    let [movieDetails, setMovieDetails] = useState([])

    useEffect(()=> {
        const params = {
            'language': 'zh-HK'
        };
    
        let urlParmas = objToString(params);
    
        fetch(API_OBJ.API_DOMAIN + '/3/movie/'+ urlParams.id + urlParmas, GET_option)
        .then(response => response.json())
        .then(response => {
            // check if movieID has details, then setMovieDetails
            if(!response.hasOwnProperty("success")) {
                setMovieDetails(response);  
            } else {
                setMovieDetails([]);
            }
        })
        .catch(err => console.error(err));
      }, [])
      //console.log(movieDetails)
      console.log(imgJSON)


    return (
        <>
            <div className='backBtn' onClick={() => navigate(-1)}> <FontAwesomeIcon icon={faAngleLeft} /> 返回</div> 
            {
                (movieDetails.length == 0 ) ?
                    <div className='single_movie_container'>
                        <div className='errorMsg'>Loading ...</div>
                    </div>

                :
                    
                    <div className='single_movie_container'>
                        <div className='movie_poster'>
                            <img src={ (movieDetails.backdrop_path == null) ? ImgDefault : imgJSON.images.secure_base_url + imgJSON.images.backdrop_sizes[2] + movieDetails.backdrop_path } />
                        </div>
                        <div className='movie_info_container'>
                            <h1>{movieDetails.title}</h1>
                            
                            <div className='movie_date_runtime_rate'>                                
                                <p className='release_date_runtime'>上映日期: { stringToDate(movieDetails.release_date) } | {movieDetails.runtime} mins | { movieDetails.spoken_languages.map( (lang, i) => (i ? ', ' : '') + lang['name'] ) }</p>
                                <p className='vote_average'> <FontAwesomeIcon icon={faStar} /> { (Math.round(movieDetails.vote_average * 100) / 100).toFixed(1)}</p>
                            </div>
                            
                            <div className='movie_categories'>
                                <p>
                                    {
                                        movieDetails.genres.map( (cate) => <span key={cate['id']}>{cate['name']}</span> )
                                    }
                                </p>
                            </div>

                            <p className='movie_overview'>{movieDetails.overview}</p>

                            <MovieCast movieID={movieDetails.id}/>

                            {
                                movieDetails.belongs_to_collection != null &&
                                    <div className='belongs_collection_container'>                                        
                                        <div><h3>電影系列</h3></div>
                                        {/* <Link to={"/movie-details/" + movieDetails.belongs_to_collection.id}> */}
                                            <div className='collection_card' id={'collection_' + movieDetails.belongs_to_collection.id}>
                                                <div className='collection_poster'>
                                                    <img src={ (movieDetails.belongs_to_collection.backdrop_path == null) ? ImgDefault : imgJSON.images.secure_base_url + imgJSON.images.backdrop_sizes[2] + movieDetails.belongs_to_collection.backdrop_path } />
                                                </div>
                                                <div className='collection_name'>
                                                    { movieDetails.belongs_to_collection.name}
                                                </div>
                                            </div>
                                        {/* </Link> */}
                                    </div>
                                   
                            }
                                            
                        </div>
                    </div>
            }
            <MovieReviews movieID={movieDetails.id} />
            
        </>
    )
}

export default SingleMovie