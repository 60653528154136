import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { stringToDate } from '../MovieFunctions.js';

function MovieCard( {movieId, imgURL, releaseDate, movieTitle, voteAverage} ) {


  return (
    <div className='movie_card' id={"movie_"+movieId} key={movieId}>
      <Link to={"/movie-details/" + movieId}>
        <div className='poster'><img src={ imgURL } alt={movieTitle} /></div>
        <div className='movie_info'>
            <p className='movie_release_date'> 上映日期: { stringToDate(releaseDate) } </p>
            <h3 className='movie_title'> { movieTitle } </h3>
            <p className='vote_average'> <FontAwesomeIcon icon={faStar} /> <span>{ (Math.round(voteAverage * 100) / 100).toFixed(1)} </span></p>
        </div>
      </Link>
    </div>
  )
}

export default MovieCard