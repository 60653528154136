import React, { useEffect, useState, useContext } from 'react'
import { objToString } from '../MovieFunctions.js';
import {API_OBJ, GET_option} from '../APIConfig'
import { MovieContext } from './MovieContext';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons'

import ImgDefault from '../logo.svg';


function MovieReviews({movieID}) {

    const {imgJSON} = useContext(MovieContext);
    let [reviewsData, setReviewData] = useState([]);

    // !!IMPORTANT!! - Variables outside of useEffect MUST be included in the Dependency array. Ref: https://www.youtube.com/watch?v=lbHuwpPwfoc
    useEffect(()=>{
        async function fetchData() {
            const params = {
                'page': 1,
                'language': 'en-US'
            };
        
            let urlParmas = objToString(params);

            const request = await fetch(API_OBJ.API_DOMAIN + '/3/movie/' + movieID + '/reviews' + urlParmas, GET_option)
            .then(response => response.json())
            .then(response => {
                setReviewData(response);
            })
            .catch(err => console.error(err));
            return request;
        }
        fetchData();
    }, [movieID]) 


    console.log(reviewsData)
    

    return (
        <>
        
            {   !reviewsData.hasOwnProperty("success") && 
                reviewsData.length != 0 &&
                reviewsData.total_results != 0 && 
                imgJSON.length != 0 && 
                    <div className='reviews_container'>
                        <h2>評論</h2>
                        { reviewsData.results.map((review) => 
                        
                            <div className='author_reviews' key={review.id}>
                                <div className='author_info'>
                                    { review.author_details.avatar_path != null ?
                                        <div>
                                            <img src={imgJSON.images.secure_base_url + imgJSON.images.backdrop_sizes[1] + review.author_details.avatar_path} alt={review.author} className='author_avatar'/>
                                        </div>
                                        :
                                        <div><img src={ImgDefault} alt={review.author} className='author_avatar'/></div>
                                    }
                                    
                                    <div>
                                        <p className='author_name'>{review.author}</p>
                                        <p className='review_details'>
                                            <span>{new Date(review.created_at).toLocaleDateString('en-GB')} | </span>
                                            <span className='author_rate'><FontAwesomeIcon icon={faStar} /> <span>{ (Math.round(review.author_details.rating * 100) / 100).toFixed(1)} </span></span>
                                        </p>
                                    </div>
                                </div>
                                <div className='reviews'>
                                    <p>{ review.content}</p>
                                </div>
                            </div>
                            
                        ) }
                    </div>
            }
        
        </>
    )
}

export default MovieReviews