import React, { useEffect, useState, useContext } from 'react'
import { objToString } from '../MovieFunctions.js';
import {API_OBJ, GET_option} from '../APIConfig'
import { MovieContext } from './MovieContext';

import Slider from "react-slick";
import ImgDefault from '../logo.svg';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MovieCast( {movieID} ) {

    const {imgJSON} = useContext(MovieContext);
    let [castData, setCastData] = useState([]);

    useEffect(()=>{
        
        async function fetchData() {
            const params = {
                'language': 'en-US'
            };
            let urlParmas = objToString(params);

            const request = await fetch(API_OBJ.API_DOMAIN + '/3/movie/' + movieID + '/credits' + urlParmas, GET_option)
            .then(response => response.json())
            .then(response => {
                setCastData(response);
            })
            .catch(err => console.error(err));
            
            return request;
        }
        
        fetchData();
        
    }, [movieID])

    console.log(castData);

    var slick_settings = {
        className: 'cast_slider',
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2
                }
            },
        ]
      };

    return (
        <>
        {
            !castData.hasOwnProperty("success") && 
            castData.length != 0 && 
            imgJSON.length != 0 && 

                <div className='cast_container'>
                    <h2>演員</h2>

                    <div className='slick_container'>
                        <Slider {...slick_settings}>
                        {
                            castData.cast.map((cast) => 
                                <div className='cast' key={cast.id}>
                                    { cast.profile_path !== null ? 
                                        <img src={imgJSON.images.secure_base_url + imgJSON.images.backdrop_sizes[1] + cast.profile_path} alt={cast.name} />
                                        :
                                        <img src={ImgDefault} alt={cast.name} />
                                    }
                                    <p className='cast_name'>{cast.name}</p>
                                    <p className='character'>{cast.character}</p>
                                </div>
                            )
                        }
                        </Slider>
                    </div>

                </div>
        }
        </>
    )
}

export default MovieCast