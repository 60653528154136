import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { objToString, stringToDate } from '../MovieFunctions.js';
import {API_OBJ, GET_option} from '../APIConfig'
import { MovieContext, MovieNowPlayingContext } from './MovieContext.js';
import MovieCard from './MovieCard.js';
import ImgDefault from '../logo.svg';
import LoadMoreNowPlayingBtn from './LoadMoreNowPlayingBtn.js';
import PageTitle from './PageTitle.js';


function Home() {
  // Get imgJSON value from App Component
  const {imgJSON} = useContext(MovieContext);


  /**
   * nowPlayingInfo - 儲存整個movie json
   * 用來拎取整個movie list資料 (eg: list date range)
   */
  let [nowPlayingInfo, setNowPlayingInfo] = useState([]);

  /**
   * movieNowPlaying - 儲存整個 movie list results json
   * 用來拎取整個每個正在上映的movie
   */
  let [movieNowPlaying, setMovieNowPlaying] = useState([]);
  
  /**
   * page - 儲存目前API page 
   * 用來pass page value 去 LoadMoreBtn Component 和控制 Load more btn display
   */
  let [page, setPage] = useState('1');


  /**
   * Dependency Array是空array時, 只會在第一次website render時trigger
   */
  useEffect(()=> {
    const params = {
        'page': page,
        'language': 'zh-HK',
        'region': 'hk'
    };

    let urlParmas = objToString(params);

    fetch(API_OBJ.API_DOMAIN + '/3/movie/now_playing' + urlParmas, GET_option)
    .then(response => response.json())
    .then(response => {
      setNowPlayingInfo(response);
      setMovieNowPlaying(response.results)
    })
    .catch(err => console.error(err));
  }, [])
  
  console.log(movieNowPlaying);

  return (
    <>
    {/* pass movieNowPlaying 同 setMovieNowPlaying 去 LoadMoreBtn Component, 用來append API 下一頁JSON去movieNowPlaying */}
    <MovieNowPlayingContext.Provider value={ {movieNowPlaying, setMovieNowPlaying} } >
      
      <PageTitle title="現正上映"/>

      <div className='now_playing_info_container'>        
      { 
          nowPlayingInfo.length != 0 && 
            <>
            {/* <div className='date_range'>{ nowPlayingInfo.dates.minimum } - { nowPlayingInfo.dates.maximum }</div> */}
            <div className='date_range'>{ stringToDate(nowPlayingInfo.dates.minimum) } - { stringToDate(nowPlayingInfo.dates.maximum) }</div>
            </> 
      }
      </div>
      
      <div className='movie_cards_container'>
        {
          movieNowPlaying.length != 0 && 
            imgJSON.length != 0 &&   
            movieNowPlaying.map((movie, i)=> {
                let imgLink = '';
                if (movie['backdrop_path'] == null) {
                  imgLink = ImgDefault;
                } else {
                  imgLink = imgJSON.images.secure_base_url + imgJSON.images.backdrop_sizes[1] + movie['backdrop_path'];
                }
                return <MovieCard key={movie['id']} movieId={movie['id']} imgURL={ imgLink } releaseDate={ movie['release_date']} movieTitle={ movie['title'] } voteAverage={movie['vote_average']}  />
              }
            )


        }
          
      </div>

      { 
        page < nowPlayingInfo.total_pages && 
          <LoadMoreNowPlayingBtn page={nowPlayingInfo.page} setPage={setPage} />
      }
      
      
    </MovieNowPlayingContext.Provider>
    </>
  )
}

export default Home