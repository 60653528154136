import React, { useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons'


function Header() {

  let [isNavDropdown, setIsNavDropdown] = useState(false);
  let [isMobileMenu, setIsMobileMenu] = useState(false);
  let [isBurgerMenuOpen, setToggleBurgerMenu] = useState(false);


  const handleNavDropdown = (e) => {
    e.preventDefault();
    setIsNavDropdown(!isNavDropdown)
  }

  const handleMobileMenu = (e) => {
    e.preventDefault();
    setIsMobileMenu(!isMobileMenu);

    console.log(e.currentTarget)
    //navigate(e.currentTarget.href)
  }


  const handleBurgerMenu = (e) => {
    e.preventDefault();
    setToggleBurgerMenu(!isBurgerMenuOpen);
  }
  
  
  const MenuBtn = ({path, name}) => {
    let navigate = useNavigate();
    
    const handleNavBtn = (e) => {
      e.preventDefault();
      navigate(path)    
      setToggleBurgerMenu(!isBurgerMenuOpen);
    }

    return <button onClick={handleNavBtn} className='navBtn'> {name} </button>
  }



  /* 以下不用<Link>來做redirection, 因為<Link>不能同時handle redirect link 同onClick, 所以這裡改為用<button> */
  return (
    <header>
        <div className="header_container">
            <div className="logo_container">
                <div className='site_name'><Link to="/">React Movie</Link></div>
            </div>
            
            <div className='menu_icon'><Link to="#" onClick={handleBurgerMenu} onTouchEnd={handleBurgerMenu}><FontAwesomeIcon icon={faBars} /></Link></div>
            
            <div className={'nav_container ' + ((isBurgerMenuOpen)? ' showMobileMenu' : '')}>

              <ul className='nav'>
                  <li><MenuBtn path="/" name="Home"/></li>
                  <li><MenuBtn path='/about' name='About' /></li>
                  <li className='dropdown_li'>
                    <Link to="#" onClick={handleNavDropdown} onTouchEnd={handleNavDropdown}>Movie <FontAwesomeIcon icon={faCaretDown} className='menu_arrow_down' /> </Link>
                    <ul className={'dropdownList ' + ((isNavDropdown)? 'showNavDropdown': '')} onMouseLeave={handleNavDropdown}>
                      <li><MenuBtn path='/' name='Now Playing' /></li>
                      <li><MenuBtn path='/upcoming' name='Upcoming' /></li>
                    </ul>
                  </li> 
              </ul> 

            </div>


        </div>
    </header>
  )
}

export default Header