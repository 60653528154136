import React, { useEffect, useState, useContext } from 'react'
import PageTitle from './PageTitle'

import { objToString, stringToDate } from '../MovieFunctions.js';
import {API_OBJ, GET_option} from '../APIConfig'
import { MovieContext, MovieUpComingContext } from './MovieContext';
import MovieCard from './MovieCard.js';
import ImgDefault from '../logo.svg';
import LoadMoreNowPlayingBtn from './LoadMoreNowPlayingBtn.js';


function UpComing() {
    const {imgJSON} = useContext(MovieContext);

    let [info, setInfo] = useState([]);
    let [upComingList, setUpComingList] = useState([]);
    let [page, setPage] = useState('1');


    useEffect(()=>{
        const params = {
            'page': page,
            'language': 'zh-HK',
            'region': 'hk'
        };
    
        let urlParmas = objToString(params);
    
        fetch(API_OBJ.API_DOMAIN + '/3/movie/upcoming' + urlParmas, GET_option)
        .then(response => response.json())
        .then(response => {
          setInfo(response);
          setUpComingList(response.results)
        })
        .catch(err => console.error(err));
    }, [])

  return (
    <>
    <MovieUpComingContext.Provider value={ {upComingList, setUpComingList} } >
    <div className='upcoming_container'>
        <PageTitle title='即將上映' />

        <div className='now_playing_info_container'>        
        { 
            info.length != 0 && 
            <>
            <div className='date_range'>{ stringToDate(info.dates.minimum) } - { stringToDate(info.dates.maximum) }</div>
            </> 
        }
      </div>

      <div className='movie_cards_container'>
        {
          upComingList.length != 0 && 
            imgJSON.length != 0 &&   
            upComingList.map((movie, i)=> {
                let imgLink = '';
                if (movie['backdrop_path'] == null) {
                  imgLink = ImgDefault;
                } else {
                  imgLink = imgJSON.images.secure_base_url + imgJSON.images.backdrop_sizes[1] + movie['backdrop_path'];
                }
                return <MovieCard key={movie['id']} movieId={movie['id']} imgURL={ imgLink } releaseDate={ movie['release_date']} movieTitle={ movie['title'] } voteAverage={movie['vote_average']}  />
              }
            )


        }
          
      </div>

      { 
        page < info.total_pages && 
          <LoadMoreNowPlayingBtn page={info.page} setPage={setPage} />
      }


    </div>
    </MovieUpComingContext.Provider>
    </>
  )
}

export default UpComing