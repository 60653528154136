import React from 'react'
import { Link } from 'react-router-dom'
import PageTitle from './PageTitle'

function About() {
  return (
    <>
    <div className='about_container'>
      <PageTitle title='About' />
      <p>This project is intended for practicing React. The movie data is provided by <Link to="https://www.themoviedb.org/" target='_blank'>The Movie Database (TMDB)</Link>.</p>
      <p><Link to='https://developer.themoviedb.org/docs' target='_blank'>TMDB API</Link></p>
    </div>
    </>
  )
}

export default About