

export const objToString = (obj) => {
    let objString = '?';

    for (const [key, isLast] of Object.keys(obj)
        .map((key, i) => [key, i === Object.keys(obj).length - 1])) {
            objString += key + "=" + obj[key];
            if (!isLast) {
                objString += '&';
            }
    }
    return objString;
} // objToString()



export const stringToDate = (date) => {
    var [YYYY, MM, DD] = date.split('-');

    return DD+'/'+MM+'/'+YYYY;
}
