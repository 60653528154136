/**
 * Global settings
 */

export const API_OBJ = {
  "API_DOMAIN": "https://api.themoviedb.org",
  "API_TOKEN": "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0NzA1OTg2OTkxYzg5YzI0MTczNjE3MDg5ODY0MzI1YiIsInN1YiI6IjY1NzY5NGU2ZWMzNzBjMDExZGE5ZDk4MiIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.9jTPMZ7LJlAmG_swhfzngSpUk5Ag18cCauTlNWYG05U",
  "API_KEY": "4705986991c89c24173617089864325b"
};


export const GET_option = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: 'Bearer ' + API_OBJ.API_TOKEN
    }
};