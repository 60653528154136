import './App.css';
import { Routes, Route } from 'react-router-dom'
import { MovieContext } from './Components/MovieContext';
import {API_OBJ, GET_option} from './APIConfig'
import { useEffect, useState } from 'react';

import Header from './Components/Header';
import Home from './Components/Home';
import About from './Components/About';
import SingleMovie from './Components/SingleMovie';
import ScrollToTop from './Components/ScrollToTop';
import UpComing from './Components/UpComing';

function App() {

  /************ Get image config ************/
  /**
   * 主要目的係拎圖片domain name
   * 因為其他Component都會用到, 所以寫在最上層App Component
   * 用 useContext 去 pass image config資料
   */
  const [imgJSON, setImgJSON] = useState([]);

  useEffect(() => {
    fetch(API_OBJ.API_DOMAIN+'/3/configuration', GET_option)
      .then(response => response.json())
      .then(response => setImgJSON(response))
      .catch(err => console.error(err));
  }, [])
  /************ (end) Get image config ************/

  return (
    <>
    {/* 用 MovieContext 去 pass imgJSON and setImgJSON 到其他Child Components */}
    <MovieContext.Provider value={ {imgJSON, setImgJSON} } >
      <Header />
      <div className='content_container'>
        <Routes>
          <Route path="/" element={ <Home />} />
          <Route path="/about" element={ <About />} />
          <Route path="/movie-details" element= { <SingleMovie /> } >
            <Route path=":id" element={ <SingleMovie /> } />
          </Route>
          <Route path="/upcoming" element={ <UpComing />} />
        </Routes>
      </div>
      <ScrollToTop />
    </MovieContext.Provider>
    
    </>
  );
}

export default App;
